import * as React from 'react';
import { useState, useEffect } from 'react';
import * as API from '../../Utils/API';
import { htmlIf } from '../../Utils/HTML';
import { DismissibleModal } from '../../Components/Modal';
import { formatUSDWithoutCents } from '../../Utils/Currency';

type Props =
  { monthlySubscriptionPriceCents: number
  , annualSubscriptionPriceCents: number
  , isSubscriptionActive: boolean
  , htmlClass: string
  , buttonText: string
  }

enum Modal
  { NoModal
  , UpgradeMembership
  , CancelSubscription
  , CancellationReason
  , OfferFreeMonths
  , AcceptFreeMonths
  , ConfirmCancel
  }

enum MembershipOption
  { Free = 'free'
  , Monthly = 'monthly'
  , Annual = 'annual'
  }

enum DowngradeReasons
  { Pricing = 'Pricing'
  , ServiceQuality = 'Service Quality'
  , UsageFrequency = 'Usage Frequency'
  , ChangeInNeeds = 'Change in Needs'
  , FoundHelpElsewhere = 'Found Help Elsewhere'
  , Other = 'Other'
  }

const EditSubscriptionButton = (props: Props) => {
  const [modal, setModal] = useState(Modal.NoModal);
  const [selectedMembershipOption, setSelectedMembershipOption] = useState(MembershipOption.Free);

  const [downgradeReason, setDowngradeReason] = useState<DowngradeReasons>();
  const [downgradeFeedback, setDowngradeFeedback] = useState('');

  function handleButtonClicked() {
    if (props.isSubscriptionActive === true) {
      setModal(Modal.CancelSubscription);
    } else {
      setModal(Modal.UpgradeMembership);
    }
  }

  function handleUpgradeButtonClicked() {
    const postBody = {
      subscriptionDuration: selectedMembershipOption,
      customSubmitMessage: "After completing this form, you will be redirected back to Lucia to enjoy the benefits of your upgraded membership!"
    }
    API.post("api_subscriptions_upgrade_agent_subscription_checkout_path", postBody).then(function (result) {
      window.location.href = result['url']
    })
  }

  function handleCancelButtonClicked() {
    const postBody = {
      cancelReason: downgradeReason,
      feedback: downgradeFeedback
    }
    API.post("api_subscriptions_cancel_agent_subscription_path", postBody).then(function (result) {
      setModal(Modal.ConfirmCancel);
    })
  }

  function dismissAndReload() {
    window.location.href = '/agent/account/billing'
  }

  const ViewModal = () => {
    switch (modal) {
      case Modal.NoModal:
        return null
      case Modal.CancelSubscription:
        return (
          <DismissibleModal
            title={<h4>Membership Downgrade</h4>}
            body={
              <div className="fs-md">
                <div>
                  You currently have an active membership with Lucia.
                  As a Lucia Pro member, you’re receiving discounted service fees and access to premium support
                  (while also helping to support the growth & development of the platform!)
                </div>
                <div className="mt-2">
                  While you’ll still be able to use Lucia after downgrading, we won’t be able to offer you
                  discounted service fees or premium support anymore. Are you sure you want to downgrade to the free plan?
                </div>
                <div className="d-flex flex-column mt-3 justify-content-between">
                  <button className="btn btn-outline-danger btn-sm" onClick={() => setModal(Modal.CancellationReason)}>
                    Downgrade membership
                  </button>
                  <button className="btn btn-primary btn-sm mt-2" onClick={() => setModal(Modal.NoModal)}>
                    Keep my membership
                  </button>
                </div>
              </div>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.CancellationReason:
        return (
          <DismissibleModal
            title={<h4>Membership Downgrade</h4>}
            body={
              <div className="fs-md">
                <div>
                  Before you go, please let us know why you’re choosing to downgrade your membership – this helps
                  us to improve our services for the future!
                </div>
                <div className="mt-3">
                  <div className="fs-md fw-semibold text-dark mb-1">Downgrade reason</div>
                    {Object.values(DowngradeReasons).map((reason, index) => (
                      <div className="mb-1">
                        <input id={reason} className="form-check-input" key={index}
                          type="radio" checked={downgradeReason === reason}
                          onChange={() => setDowngradeReason(reason)}
                        />
                        <label className="fs-sm ps-1" htmlFor={reason}>{reason}</label>
                      </div>
                    ))}
                </div>
                <div className="mt-3">
                  <div className="fs-md fw-semibold text-dark mb-1">Additional feedback</div>
                  <textarea
                    className="form-control t--additional-feedback"
                    rows={4}
                    placeholder='Please share any additional feedback on why you’re choosing to downgrade…'
                    onChange={(e) => setDowngradeFeedback(e.target.value) }
                    value={downgradeFeedback}
                  />
                </div>
                <div className="d-flex flex-column mt-3 justify-content-between">
                  <button className="btn btn-outline-danger btn-sm" onClick={handleCancelButtonClicked}>
                    Confirm membership downgrade
                  </button>
                  <button className="btn btn-primary btn-sm mt-2" onClick={() => setModal(Modal.NoModal)}>
                    Keep my membership
                  </button>
                </div>
              </div>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.UpgradeMembership:
        const greenCheckItem = (bold: boolean, text: string) => (
          <div className="mt-1 d-flex align-items-center">
            <i className="ai-check fs-xl text-success me-1" />
            <div className={`${bold ? 'fw-semibold' : ''} text-gray-900`}>{text}</div>
          </div>
        )
        return (
          <DismissibleModal
            dialogClass='modal-xl'
            title={<h4>Upgrade membership</h4>}
            body={
              <>
                <div className="row">
                  <div className="col-4">
                    <div className="card rounded-1 p-2 h-100 cursor-pointer"
                      onClick={() => setSelectedMembershipOption(MembershipOption.Free)}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="fs-md fw-semibold text-gray-900">Starter</div>
                        <input className="form-check-input" type="radio" checked={selectedMembershipOption === MembershipOption.Free}></input>
                      </div>
                      <div className="mt-1">Free</div>
                      {greenCheckItem(true, 'Standard service fees')}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card rounded-1 p-2 h-100 cursor-pointer"
                      onClick={() => setSelectedMembershipOption(MembershipOption.Monthly)}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="fs-md fw-semibold text-gray-900">Monthly</div>
                        <input className="form-check-input" type="radio" checked={selectedMembershipOption === MembershipOption.Monthly}></input>
                      </div>
                      <div className="mt-1">{formatUSDWithoutCents(props.monthlySubscriptionPriceCents)}/month</div>
                      {greenCheckItem(true, 'Reduced service fees')}
                      {greenCheckItem(false, 'Live support')}
                      {greenCheckItem(false, 'Dedicated account manager')}
                      {greenCheckItem(false, 'White glove onboarding')}
                      {greenCheckItem(false, 'Exclusive features & invitations')}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card rounded-1 p-2 h-100 cursor-pointer"
                      onClick={() => setSelectedMembershipOption(MembershipOption.Annual)}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="fs-md fw-semibold text-gray-900">Annual</div>
                        <input className="form-check-input" type="radio" checked={selectedMembershipOption === MembershipOption.Annual}></input>
                      </div>
                      <div className="mt-1">{formatUSDWithoutCents(props.annualSubscriptionPriceCents / 12)}/month (billed annually)</div>
                      {greenCheckItem(true, 'Reduced service fees')}
                      {greenCheckItem(false, 'Live support')}
                      {greenCheckItem(false, 'Dedicated account manager')}
                      {greenCheckItem(false, 'White glove onboarding')}
                      {greenCheckItem(false, 'Exclusive features & invitations')}
                    </div>
                  </div>
                </div>
                <div className="text-end mt-3">
                  <button className="btn btn-primary"
                    disabled={selectedMembershipOption === MembershipOption.Free}
                    onClick={handleUpgradeButtonClicked}
                  >
                    Upgrade
                  </button>
                </div>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.ConfirmCancel:
        return (
          <DismissibleModal
            title={<h4>Membership Downgrade</h4>}
            body={
              <div className="fs-md text-secondary">
                <div>
                  You have been downgraded to a free plan.
                </div>
                <button className="btn btn-primary btn-sm mt-3" onClick={dismissAndReload}>
                  Okay
                </button>
              </div>
            }
            onDismiss={dismissAndReload}
          />
        )
    }
  }

  return (
    <>
      <button className={props.htmlClass} onClick={handleButtonClicked}>
        {props.buttonText}
      </button>
      {htmlIf(modal !== Modal.NoModal,
        ViewModal()
      )}
    </>
  )
}

export default EditSubscriptionButton;

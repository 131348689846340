import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import * as API from '../Utils/API.js';
import { updateWhere, modifyWhere } from '../Utils/Array';
import { htmlIf, formatMultiParagraphString, formatMarkdown, maybeHtml } from '../Utils/HTML';
import { lastSeenAtDescription, timeAgo } from '../Utils/DateTime';
import { Meeting } from '../Types/Meeting';
import { Tooltip } from 'react-tooltip'
import { ScheduleMeetingModal } from './Components/ScheduleMeetingModal';
import { DismissibleModal } from '../Components/Modal';

type Props =
  { conversations: Conversation[]
  , currentUser: User
  // When `isCopilotView` is set to true, we know that the Messages page is being used from the Copilot side
  // of the platform. Accordingly, we show a different empty state than on the Agent side of the platform.
  , isCopilotView: boolean
  // We're currently testing whether or not to format messages with markddown (using ReactMarkdown). This
  // is passed via a feature flag.
  , formatWithMarkdown: boolean
  // Sometimes, we'll want to have the page load and immediately jump to a particular message thread. We can do that
  // by setting the presetConversationId property. Conversation IDs can be generated by the `DirectMessageService` in Ruby.
  , presetConversationId?: string
  , newRequestPath: string
  }

type Conversation =
  { messages: Message[]
  , otherParty: User
  , conversationId: string
  , meetings: Meeting[]
  }

type Message =
  { id: number
  , body: string
  , attachment?:
    { name: string
    , url: string
    }
  , fromUserId: number
  , toUserId: number
  , isSystemMessage: boolean
  , readAt?: string
  , sentAt: string
  }

type User =
  { name: string
  , userId: number
  , avatarUrl: string
  , lastSeenAt: string
  , isVirtuoso: boolean
  // This value is TRUE only if the user is a CoPilot and they have indicated that they are NOT open to work (i.e. they are OOO).
  , copilotIsOOO: boolean
  , copilotProfilePath?: string
  }

const copilotOpenRequestsPath = '/copilot'

const Messages = (props: Props) => {
  const [conversationList, setConversationList] = useState(props.conversations);
  const [selectedConversation, setSelectedConversation] = useState<Conversation>(
    props.conversations.find(conversation => conversation.conversationId === props.presetConversationId) ??
    props.conversations[0] ?? null
  );
  const [otherUserIsTyping, setOtherUserIsTyping] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [filteredConversations, setFilteredConversations] = useState(props.conversations);

  const [newMessageFiles, setNewMessageFiles] = useState<File[]>([]);

  // Prior to 11/14/23, we stored newMessageBody as a state variable, which was updated whenever the new message text area
  // was changed. This was causing the full messages component to re-render every time a key was pressed in the new message
  // text area, which was badly slowing down the messaging experience.
  // Instead, we now use a ref (newMessageBodyRef) to access the text area, and only update state (via isNewMessageBodyTextPresent)
  // when the text area input changes from blank to not blank (when blank, we disable the "Send" button. When not blank, we enable it).
  const [isNewMessageBodyTextPresent, setIsNewMessageBodyTextPresent] = useState(false);
  const newMessageBodyRef = useRef(null);

  const [showPersonalInfoWarning, setShowPersonalInfoWarning] = useState(false);

  const [isSchedulingMeeting, setIsSchedulingMeeting] = useState(false);

  // When set, indicates that we're showing the confirmation modal to cancel the specified meeting.
  const [cancellingMeeting, setCancellingMeeting] = useState<Meeting>(null);
  const [isSavingCancel, setIsSavingCancel] = useState(false);

  const messagesEndRef = useRef(null)
  const selectedConversationRef = useRef(selectedConversation);
  const typingIndicatorSubscription = useRef<ActionCable.Channel>();

  // Subscribe to the MessagesChannel, which will receive a broadcast any time a new message is sent to the current user.
  // This broadcast is managed in the Messages::Controller in Ruby.
  useEffect(() => {
    const messagesSubscription = App.cable.subscriptions.create("Messages::NewMessageChannel", {
      received: (updatedConversationList) => {
        setConversationList(updatedConversationList);
        const currentSelectedConversation = selectedConversationRef.current;
        if (currentSelectedConversation !== null && currentSelectedConversation !== undefined) {
          setSelectedConversation(
            updatedConversationList.find((conversation) => conversation.conversationId === currentSelectedConversation.conversationId)
          );
        }
      }
    });

    // Unsubscribe when the component unmounts.
    return () => {
      messagesSubscription.unsubscribe();
    };
  }, [])

  // Auto-scroll to the bottom of the conversation thread any time a new conversation is selected.
  useEffect(() => {
    scrollMessagesToBottom();
  }, [selectedConversation, conversationList])

  function scrollMessagesToBottom() {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest"})
  }

  // Subscribe to the Messages::TypingIndicatorChannel, which will receive a broadcast when another user is typing
  // in the same "room" (where the "room" is identified by the conversation ID as defined in the DirectMessageService).
  // Since typing is a client-side event, this broadcast is managed right here in this component — see the ViewConversation()
  // function for more context.
  useEffect(() => {
    selectedConversationRef.current = selectedConversation;
    setOtherUserIsTyping(false);

    typingIndicatorSubscription.current = App.cable.subscriptions.create(
      { channel: "Messages::TypingIndicatorChannel", conversationId: selectedConversation?.conversationId },
      {
        received(data) {
          console.log(data);
          if (data.typingUserId !== props.currentUser.userId) {
            setOtherUserIsTyping(data.isTyping);
          }
        }
      }
    );

    // Unsubscribe when another conversation is selected.
    return () => {
      typingIndicatorSubscription.current.unsubscribe();
    };
  }, [selectedConversation]);

  // Whenever the search text is changed (or the conversation list is updated), re-filter the conversation list.
  useEffect(() => {
    const filtered = conversationList.filter((conversation) => {
      const searchFields = [
        conversation.otherParty.name
      ];
      return (
        searchFields.some((field) =>
          field?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    setFilteredConversations(filtered);
  }, [conversationList, searchText])

  // Courtesy of ChatGPT, triggers sendMessage() when Command + Enter / Ctrl + Enter are pushed.
  const handleSendMessageKeysDown = useCallback((event) => {
    // For Mac (Command + Enter) and Windows/Linux (Ctrl + Enter)
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault(); // Prevent default Enter behavior

      // Ensure the same conditions as the button's disabled state
      if (isNewMessageBodyTextPresent || newMessageFiles.length > 0) {
        sendMessage();
      }
    }
  }, [isNewMessageBodyTextPresent, newMessageFiles]); // Track changes to both states

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      document.addEventListener('keydown', handleSendMessageKeysDown);

      return () => {
        document.removeEventListener('keydown', handleSendMessageKeysDown);
      };
    }
    return () => {};
  }, [handleSendMessageKeysDown]);


  function sendMessage() {
    let currentConversation = selectedConversation
    let formData = new FormData();
    formData.append("body", newMessageBodyRef.current.value);
    for (var i = 0; i < newMessageFiles.length; i++) {
      formData.append("files[]", newMessageFiles[i]);
    }
    formData.append("fromUserId", props.currentUser.userId.toString());
    formData.append("toUserId", currentConversation.otherParty.userId.toString());
    formData.append("conversationId", currentConversation.conversationId);
    newMessageBodyRef.current.value = "";
    setIsNewMessageBodyTextPresent(false);
    setNewMessageFiles([]);
    API.postFormData('messages_send_message_path', formData).then(function (result) {
      const updatedConversationList = result
      setConversationList(updatedConversationList);
      const currentSelectedConversation = selectedConversationRef.current;
        if (currentSelectedConversation !== null && currentSelectedConversation !== undefined) {
          didSelectConversation(
            updatedConversationList.find((conversation) => conversation.conversationId === currentSelectedConversation.conversationId)
          );
        }
    })
  }

  function markRead(conversation: Conversation) {
    const postBody = { conversationId: conversation.conversationId }

    API.post('messages_mark_read_path', postBody).then(function () {
      setConversationList((conversationList) =>
        modifyWhere(
          (c: Conversation) => c.conversationId === conversation.conversationId,
          (c: Conversation) => ({...c, messages: modifyWhere(
            (m: Message) => m.readAt === null,
            (m: Message) => ({...m, readAt: new Date().toString()}),
            c.messages
          )}),
          conversationList
        )
      )
    })
  }

  function reloadPersonalInfoWarning():boolean {
    let text = newMessageBodyRef.current?.value;

    if (text === undefined || text === null) {
      return false
    } else {
      // Regular expression for matching email addresses
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

      // Regular expression for matching phone numbers in various formats
      // Can't find a phone regex that works just yet
      // const phoneRegex = /\b(?:\+?\d{1,3}[-.●]?)?(?:\(\d{1,4}\)[-.\●]?)?(?![-.\d])(?:\d{1,15}[-.\/\s]?\d{1,15}[-.\/\s]?\d{1,15}(?:[-.●]?\d{1,6})?)(?=\D|$)\b/g;

      // Check if either email or phone number is present in the text
      const emailMatch = text.match(emailRegex);
      // const phoneMatch = text.match(phoneRegex);

      // return emailMatch !== null || phoneMatch !== null;
      return emailMatch !== null;
    }
  }

  function didSelectConversation(conversation: Conversation) {
    setSelectedConversation(conversation);
    markRead(conversation);
  }

  function numUnreadMessages(conversation: Conversation): number {
    return conversation.messages.filter((message) =>
      (!message.readAt && message.fromUserId != props.currentUser.userId)).length
  }

  const ViewConversationTitleCard = ({ conversation }: { conversation: Conversation }) => {
    const isActive = selectedConversation?.conversationId === conversation.conversationId
    return (
      <div
        className={`d-flex align-items-center rounded-1 text-decoration-none p-2 hover-gray-100 border-bottom t--conversation-card ${isActive ? "bg-gray" : ""}`}
        style={{cursor: 'pointer'}}
        onClick={() => didSelectConversation(conversation)}
        data-bs-dismiss='offcanvas'
        data-bs-target='#contactsList'
        >
        <div className="flex-shrink-0 my-1 avatar avatar-sm">
          <img src={conversation.otherParty.avatarUrl} className='avatar-img rounded-circle'/>
        </div>
        <div className="overflow-hidden w-100">
          <div className="d-flex justify-content-between align-items-center w-100 px-1 ms-1 my-1">
            <div className="me-2">
              <div className="fs-md fw-bold text-dark">{conversation.otherParty.name}</div>
            </div>
            <div className="text-end">
              <span className="d-block fs-xs text-muted">
                {timeAgo(new Date(conversation.messages[conversation.messages.length -1].sentAt))}
              </span>
            </div>
          </div>
          <div className="ps-1 ms-1 mt-1 fs-sm truncate">
            {conversation.messages[conversation.messages.length - 1].body}
          </div>
        </div>
        {htmlIf(numUnreadMessages(conversation) > 0,
          <span className="badge bg-danger fs-xs lh-1 py-1 px-1 ms-1">{numUnreadMessages(conversation)}</span>
        )}
      </div>
    )
  }

  /**
   * Based on the provided message index and message list, determines if a date separator (a line across the
   * conversation with the date, indicating that previous messages are from a prior day and following messages
   * are from the listed day) is required, and, if so, renders the date separator.
   * @param index - The index of the current message (the one following the potential date separator)
   * @param messages — The full list of messages
   * @returns A React component that will render the date separator, if appropriate.
   */
  const RenderDateSeparator = ({ messageIndex, messages }: { messageIndex: number, messages: Message[] }) => {
    let shouldShowSeparator = false

    if (messageIndex === 0) {
      shouldShowSeparator = true; // This is the first message, so we should show a date separator
    } else {
      const prevMessageDate = new Date(messages[messageIndex - 1].sentAt).toLocaleDateString();
      const currentMessageDate = new Date(messages[messageIndex].sentAt).toLocaleDateString();

      if (prevMessageDate !== currentMessageDate) {
        shouldShowSeparator = true; // The previous message was sent on a different date than the current message, so we should show a date separator
      }
    }

    return (
      htmlIf(shouldShowSeparator,
        <div className="text-surrounded-by-line fs-sm text-gray-700 my-3">
          {new Date(messages[messageIndex].sentAt).toLocaleDateString("en-US", { weekday: "long", day: "numeric", month: "long", year: "numeric" })}
        </div>
      )
    )
  }

  /**
    Renders a message in the conversation view.
    @param message - The message to render.
    @param endOfThought - A boolean indicating whether this message is the end of a "thought." We do
      this in order to group messages together that were sent within a few minutes of one another (much
      like how iMessage does). If `endOfThought` is true, we add a larger bottom margin to the message and
      display the time the message was sent below the message. If `endOfThought` is false, we remove the bottom
      margin and hide the time the message was sent at.
    @returns A React component representing the message.
  */
  const ViewMessage = ({ message, startOfThought, endOfThought }: { message: Message, startOfThought: boolean, endOfThought: boolean }) => {
    const fromCurrentUser = message.fromUserId === props.currentUser.userId

    function messageText() {
      return (
        <div>
          { props.formatWithMarkdown
          ? formatMarkdown(message.body)
          : formatMultiParagraphString(message.body)
          }
          {message.attachment ? (
            <div className='my-1 text-primary'>
              <a href={message.attachment.url} target='_blank' className='d-flex align-items-center text-decoration-none'>
                <i className="ai-paperclip me-1" />
                <div className="text-hover-underline">{message.attachment.name}</div>
              </a>
            </div>
          ) : null
          }
        </div>
      )
    }

    function formatDate(dateString: string):string {
      const date = new Date(dateString)
      const timeString = date.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true, timeZoneName: "short" });
      return `${timeString}`;
    }

    if (message.isSystemMessage) {
      return (
        <div className="row g-0 no-gutters align-items-start my-3">
          <div className="fs-xs text-muted">{messageText()}</div>
        </div>
      )
    }
    else {
      return (
        <div className={`row g-0 no-gutters ${startOfThought ? 'mt-3 align-items-start' : 'align-items-center'}`}>
          <div className="col-auto">
            <div className='flex-shrink-0 avatar avatar-xs'>
              <img src={fromCurrentUser ? props.currentUser.avatarUrl : selectedConversation.otherParty.avatarUrl}
                className={startOfThought ? 'avatar-img rounded-circle' : 'd-none'}
              />
            </div>
          </div>
          <div className="ms-1 col w-75">
            <div className={startOfThought ? "fs-md fw-semibold" : "d-none"} suppressHydrationWarning>
              {fromCurrentUser ? props.currentUser.name : selectedConversation.otherParty.name}
            </div>
            <div className="fs-md">
              {messageText()}
            </div>
            <div className={endOfThought ? "fs-xs text-muted" : "d-none"} suppressHydrationWarning>
              {formatDate(message.sentAt)}
            </div>
          </div>
        </div>
      )
    }
  }

  // Renders the upcoming/in-progress view for any applicable Meetings associated with a conversation.
  const ViewMeetings = (meetings: Meeting[]) => {
    const hoursAfter = 3 * 60 * 60 * 1000;   // 3 hours in milliseconds
    // Display all meetings that are scheduled to start in the future
    // or were scheduled to start within the past 3 hours.
    const applicableMeetings = meetings
      .filter(meeting => {
        const startTime = new Date(meeting.startTime).getTime();
        const now = Date.now();

        // Include future meetings and those from the last 'hoursAfter' hours
        return startTime >= now - hoursAfter;
      })
      .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());


    function formatMeetingDate(meeting) {
      const date = new Date(meeting?.startTime)
      return (
        date.toLocaleDateString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          timeZoneName: 'short',

          weekday: 'long',
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
      )
    }

    function cancelMeeting(meeting) {
      setIsSavingCancel(true);
      const body = { meetingId: meeting.id }

      API.post("api_meetings_cancel_path", body).then(function (result) {
        if (result['error']) {
        } else {
          setCancellingMeeting(null);
        }
        setIsSavingCancel(false);
      })
    }

    const ViewCancelMeetingModal = () => (
      <DismissibleModal
        title={<h4>Cancel meeting</h4>}
        body={
          <div>
            Are you sure you'd like to cancel your meeting?
            <br /><br />
            <span className="fw-semibold">{cancellingMeeting?.topic}</span>
            <br />
            Scheduled for {formatMeetingDate(cancellingMeeting)}
          </div>
        }
        footer={
          <>
            <button className="btn btn-danger w-100 w-sm-auto mb-2 mb-sm-0 t--confirm-decline-offer"
              onClick={() => cancelMeeting(cancellingMeeting)}
              disabled={isSavingCancel}
            >
              Cancel meeting
            </button>
            <button className="btn btn-outline-primary w-100 w-sm-auto ms-sm-2"
              onClick={() => setCancellingMeeting(null)}
              disabled={isSavingCancel}
            >
              Not now
            </button>
          </>
        }
        onDismiss={() => setCancellingMeeting(null)}
      />
    )

    return (
      <>
        {htmlIf(applicableMeetings.length > 0,
          <div>
            {htmlIf(cancellingMeeting?.id > 0,
              ViewCancelMeetingModal()
            )}
            {applicableMeetings.map(meeting => (
              <div key={meeting.id} className={`row align-items-center p-2 border rounded-2 mb-1 t--meeting-id-${meeting.id}`}>
                <div className="col-lg">
                  <div className="fw-semibold">{meeting.topic}</div>
                  <div className="">{formatMeetingDate(meeting)}</div>
                </div>
                <div className="col-xl-auto d-flex align-items-center mt-2 mt-xl-0">
                  {/* <a className="btn btn-link px-0 me-2">
                    <i className="ai-calendar-plus me-1" />
                    Add to calendar
                  </a> */}
                  {htmlIf(meeting.schedulingUserId === props.currentUser.userId,
                    <div className="me-2">
                      <button className="btn p-0"
                        data-tooltip-id={`cancel-meeting-${meeting.id}`}
                        data-tooltip-place="top"
                        data-tooltip-position-strategy='fixed'
                        data-tooltip-content='Cancel meeting'
                        onClick={() => setCancellingMeeting(meeting)}
                      >
                        <i className="ai-trash fs-xl text-danger"/>
                      </button>
                      <Tooltip id={`cancel-meeting-${meeting.id}`}/>
                    </div>
                  )}
                  {maybeHtml(meeting.joinUrl, (zoomUrl =>
                    <a className="btn btn-info px-2" href={zoomUrl} target={'_blank'}>
                      <i className="ai-video me-1" />
                      Join Zoom meeting
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    )
  }

  const ViewConversation = (conversation: Conversation) => {
    // This defines the maximum allowable time between two messages for them to be considered part of the same "thought."
    const numSecondsBetweenMessages = 120

    /**
     * Determines whether a particular message is the end of a "thought" — for the last message of a thought,
     * we display the time the message was sent.
     *
     * @param messageIndex - The index of the current message in the list of messages.
     * @param messages - The list of messages.
     * @returns A boolean indicating whether the message is the end of a "thought"
     */
    function shouldEndThought(messageIndex: number, messages: Message[]): boolean {
      if (messageIndex === messages.length - 1) {
        return true; // This is the last message, so we should end the thought
      }

      const message = messages[messageIndex];
      const nextMessage = messages[messageIndex + 1];

      if (new Date(nextMessage.sentAt).getTime() - new Date(message.sentAt).getTime() > (1000 * numSecondsBetweenMessages)) {
        return true; // The next message was sent more than the alloted number of seconds after this one, so we should end the thought
      }

      if (nextMessage.fromUserId !== message.fromUserId) {
        return true; // The next message was sent by a different user, so we should end the thought
      }

      return false;
    }

    /**
     * Determines whether a particular message is the start of a "thought" — for the first message of a thought,
     * we display the sender's avatar and name.
     *
     * @param messageIndex - The index of the current message in the list of messages.
     * @param messages - The list of messages.
     * @returns A boolean indicating whether the message is the start of a "thought"
     */
    function shouldStartThought(messageIndex: number, messages: Message[]): boolean {
      if (messageIndex === 0) {
        return true; // This is the first message, so we should start the thought
      }

      const prevMessage = messages[messageIndex - 1];
      const message = messages[messageIndex];

      if (prevMessage.isSystemMessage) {
        return true;
      }

      if (shouldEndThought(messageIndex - 1, messages)) {
        return true; // If the previous message was the end of a thought, then the current message is the start of a thought.
      }

      return false;
    }

    function onTypingStart() {
      typingIndicatorSubscription.current.perform("typing", {
        conversationId: conversation.conversationId,
        typingUserId: props.currentUser.userId,
        isTyping: true
      })
    }

    function onTypingEnd() {
      typingIndicatorSubscription.current.perform("typing", {
        conversationId: conversation.conversationId,
        typingUserId: props.currentUser.userId,
        isTyping: false
      })
    }

    function textAreaOnChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
      if (event.target.value === "") {
        setIsNewMessageBodyTextPresent(false)
      } else {
        isNewMessageBodyTextPresent ? null : setIsNewMessageBodyTextPresent(true)
      }

      if (reloadPersonalInfoWarning() !== showPersonalInfoWarning) {
        setShowPersonalInfoWarning(!showPersonalInfoWarning)
      }

      if (parseInt(newMessageBodyRef.current.scrollHeight) >= 192) {
        return;
      } else {
        newMessageBodyRef.current.style.height = 'auto';
        newMessageBodyRef.current.style.height = `${newMessageBodyRef.current.scrollHeight}px`;
        scrollMessagesToBottom();
      }
    }

    return (
      <div className="card rounded-2 h-100" style={{'maxHeight': '700px'}}>
        <div className="navbar card-header w-100 mx-0 p-2">
          <div className="d-flex align-items-center w-100 px-sm-2">
            <button
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#contactsList"
              aria-controls="contactsList"
              className="navbar-toggler d-lg-none me-2 me-sm-3 py-1">
              <span className="ai-chevron-left fs-xxl"></span>
            </button>
            <div className="d-flex align-items-center">
              <h5 className="h5 mb-0">{conversation.otherParty.name}</h5>
              <div className="ms-1 fs-xs">{lastSeenAtDescription(conversation.otherParty.lastSeenAt)}</div>
            </div>
            {htmlIf(conversation.otherParty.isVirtuoso,
              <div className="ms-auto badge bg-info text-white">Virtuoso Member</div>
            )}
            {htmlIf(conversation.otherParty.copilotIsOOO,
              <div className="ms-3 badge bg-faded-danger text-danger me-2">Currently OOO</div>
            )}
            {maybeHtml(conversation.otherParty.copilotProfilePath, (profileUrl) => (
              <a href={profileUrl} target="_blank" className="ms-auto btn btn-sm btn-link p-0">
                <i className="ai-external-link pe-1"/>
                View Profile
              </a>
            ))}

          </div>
        </div>
        <div className="card-body px-4 pt-2 pb-0 overflow-auto">
          {conversation.messages.map((message, index, messages) => (
            <>
              <RenderDateSeparator
                messageIndex={index}
                messages={messages}
                key={`date-separator-${message.id}`}
              />
              <ViewMessage
                message={message}
                endOfThought={shouldEndThought(index, messages)}
                startOfThought={shouldStartThought(index, messages)}
                key={message.id}
              />
            </>
          ))}
          {ViewMeetings(conversation.meetings)}
          <div className="mt-2" ref={messagesEndRef}/>
        </div>
        {htmlIf(otherUserIsTyping,
            <div className="card-body py-0 text-secondary fs-sm">{conversation.otherParty.name} is typing…</div>
        )}
        {htmlIf(showPersonalInfoWarning,
          <div className="px-3 py-2 bg-faded-danger border-danger border d-flex align-items-center">
            <i className="ai-triangle-alert fs-xxl text-danger me-2" />
            <div className="fs-sm text-danger">
              { props.isCopilotView
              ? `
                  It looks like you may be trying to share personal contact information
                  (like an email address or phone number). As a reminder, communication
                  with clients must remain on the Lucia platform. These rules help protect
                  you from unwarranted liability claims and ensure that you’re paid for your work.
                `
              : `
                  It looks like you may be trying to share personal contact information
                  (like an email address or phone number). As a reminder, communication
                  with CoPilots must remain on the Lucia platform. These rules help ensure
                  security and privacy for your clients and business and allow our CoPilots
                  to provide the highest possible level of service.
                `
              }

            </div>
          </div>
        )}
        <div className="d-flex px-2 align-items-center border-top">
          <div className="py-1">
            <button className="btn p-0"
              data-tooltip-id={"zoom"}
              data-tooltip-place="top"
              data-tooltip-position-strategy='fixed'
              data-tooltip-content='Schedule a Zoom meeting'
              onClick={() => setIsSchedulingMeeting(true)}
            >
              <i className="ai-video fs-xxl text-info"/>
            </button>
            <Tooltip id='zoom'/>
            <ScheduleMeetingModal
              schedulingUserId={props.currentUser.userId}
              invitedUserId={conversation.otherParty.userId}
              isVisible={isSchedulingMeeting}
              onClose={() => setIsSchedulingMeeting(false)}
              presetTopic={`${props.currentUser.name} <> ${conversation.otherParty.name}`}
            />
          </div>
          <div className="p-1 pe-2">
            <label htmlFor="messageAttachment" className=""
              data-tooltip-id={"attachment"}
              data-tooltip-place="top"
              data-tooltip-position-strategy='fixed'
              data-tooltip-content='Attach a file'
            >
              <i className="ai-paperclip fs-xxl text-secondary cursor-pointer"/>
            </label>
            <input
              className="d-none" type="file" multiple id="messageAttachment"
              onChange={(event) => setNewMessageFiles(Array.from(event.target.files))}
            />
            <Tooltip id='attachment'/>
          </div>
          <textarea className="form-control px-1 py-1 rounded-0 border-0 border-start d-flex align-items-center t--new-message-body"
            placeholder='Type a message…' style={{resize: 'none', lineHeight: '1.5'}} id="messageInput"
            onChange={textAreaOnChange}
            rows={2}
            ref={newMessageBodyRef}
            onFocus={onTypingStart}
            onBlur={onTypingEnd}
          />
          <button className="btn btn-link p-0 t--send-message fs-4"
            onClick={sendMessage}
            type='button'
            disabled={!isNewMessageBodyTextPresent && (newMessageFiles.length === 0)}
            data-tooltip-id={"sendMessage"}
            data-tooltip-place="top"
            data-tooltip-position-strategy='fixed'
            data-tooltip-content={
              /Mac/.test(navigator.userAgent)
                ? "Command + Enter to send"
                : /Win|Linux/.test(navigator.userAgent)
                  ? "Ctrl + Enter to send"
                  : "" // No tooltip for iOS (or other devices without a keyboard)
            }
          > <i className="ai-send" />
          </button>
          <Tooltip id='sendMessage'/>
        </div>
        <div className={newMessageFiles.length > 0 ? "px-3 pb-1 fs-sm" : 'd-none'}>
          {newMessageFiles.map((file) => (
            <div>
              Attached: <b>{file?.name?.replace(/.*[\/\\]/, '')}</b>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const ViewEmptyConversation = () => {
    return (
      <div className="card h-100">
        <div className='d-lg-none navbar card-header w-100 mx-0 px-3 py-1'>
          <button
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#contactsList"
            aria-controls="contactsList"
            className="navbar-toggler me-2 me-sm-3">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="card-body text-center d-flex justify-content-center align-items-center">
          <h3 className="m-0">Select a conversation to get started!</h3>
        </div>
      </div>
    )
  }

  const ViewEmptyState = () => {
    return (
      <div className="my-3 text-center">
        <div className="avatar avatar-md">
          <div className="avatar-title bg-accent rounded text-dark h3">
            <i className="ai-file-text" />
          </div>
        </div>
        { props.isCopilotView
          ?
          <>
            <div className="fs-lg text-dark mt-2">You don't have any messages — claim a request to get started!</div>
            <a href={copilotOpenRequestsPath} className="btn btn-primary mt-2">Claim a request</a>
          </>
          :
            <>
              <div className="fs-lg text-dark mt-2">You don't have any messages — submit a request to get started!</div>
              <a href={props.newRequestPath} className="btn btn-outline-irish-green mt-2">Submit a request</a>
            </>
        }

      </div>
    )
  }

  return (
    <div>
      { conversationList.length > 0
      ?
        <div className="rounded p-0 px-lg-3 pt-0">
          <div className="row position-relative overflow-hidden gx-2 zindex-1">
            <div className="col-lg-4">
              <div
                className="offcanvas-lg offcanvas-start position-absolute position-lg-relative h-100 bg-light rounded-2 border"
                data-bs-scroll='true'
                data-bs-backdrop='false'
                style={{'height':'100vh'}}
                id="contactsList">
                <div className="rounded-0 overflow-hidden">
                  <div className="card-header w-100 border-bottom p-2">
                    <div className="d-flex d-lg-none justify-content-between align-items-center">
                      <h5 className="mb-0">My Messages</h5>
                      <button
                        type='button'
                        data-bs-dismiss='offcanvas'
                        data-bs-target='#contactsList'
                        className="btn btn-outline-secondary border-0 px-2 me-n1">
                          <i className="ai-cross me-1"/>
                      </button>
                    </div>
                    <h5 className="mb-0 d-none d-lg-block">My Messages</h5>
                  </div>
                  <div className="card-body px-2 pb-2 overflow-auto" style={{'maxHeight': '700px'}}>
                    <div className="input-group align-items-center p-0 my-1 bg-white">
                      <div className="input-group-prepend ms-2">
                        <i className="ai-search"></i>
                      </div>
                      <input
                        className="form-control"
                        type="search"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        placeholder="Search by name…"
                      />
                    </div>
                    {filteredConversations.map((conversation, index) => (
                      <ViewConversationTitleCard
                        conversation={conversation}
                        key={conversation?.conversationId}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              { selectedConversation && conversationList.length > 0
                ? ViewConversation(selectedConversation)
                : conversationList.length > 0
                  ? <ViewEmptyConversation />
                  : <ViewEmptyState />
              }
            </div>
          </div>
        </div>
      :
        <div className="card border-0 card-body">
          <ViewEmptyState />
        </div>
      }
    </div>
  )
};


export default Messages;

import { Copilot } from './Copilot';
import { Tag } from './Tag';
import { CustomerRating } from './CustomerRating';
import { RequestScope } from './RequestScope';

export type ProjectRequest =
  { id: number
  , title: string
  , description: string
  , status: RequestStatus
  , copilot: Copilot
  , customerRating?: CustomerRating
  , customerName: string
  , customerUserId: number
  , customerImageUrl: string
  , customerHasActiveSubscription: boolean
  , isAgentVirtuoso: boolean
  , subtotalCents: number
  , platformFeeCents: number
  , copilotAmountDueCents: number
  , paidAt: string
  , claimedAt: string
  , completedAt: string
  , withdrawnAt: string
  , declinedAt: string
  , refundedAt: string
  , createdAt: string
  , updatedAt: string
  , tags: Tag[]
  , offers: ProjectRequestOffer[]
  , acceptingOffers: boolean
  , scope: RequestScope
  }

// This should be kept in sync with the ProjectRequest::Status class in `models/project_request.rb`.
export enum RequestStatus
  { MISSING_PAYMENT_METHOD = 'missing-payment-method'
  , SUBMITTED = 'submitted'
  , DECLINED_PENDING = 'declined-pending'
  , CLAIMED = 'claimed'
  , PAID_UNCLAIMED = 'paid-unclaimed'
  , UNDER_REVIEW = 'under-review'
  , COMPLETED = 'completed'
  , WITHDRAWN = 'withdrawn'
  , REFUNDED = 'refunded'
}

export type ProjectRequestOffer =
  { id: number
  , description: string
  , amountCents: number
  , createdAt: string
  , updatedAt: string
  , lastEditedAt: string
  , status: RequestOfferStatus
  , copilot: Copilot
  }

// This should be kept in sync with the ProjectRequestOffer::Status class in `models/project_request_offer.rb`
export enum RequestOfferStatus
  { PENDING = 'pending'
  , ACCEPTED = 'accepted'
  , DECLINED_MANUAL = 'declined-manual'
  , DECLINED_AUTO = 'declined-auto'
  , RESCINDED = 'rescinded'
  }

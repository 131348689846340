import * as React from 'react';
import { useState } from 'react';
import * as API from '../Utils/API.js';
import { HourlyContract, ContractStatus, HourlyContractOffer, ContractOfferStatus } from '../Types/Hourly';
import { RequestType } from '../Types/RequestCard'
import { CustomerRequestStatusBadge } from '../Common/RequestStatusBadge';
import { Copilot } from '../Types/Copilot';
import HourlyTimesheet from '../Common/HourlyTimesheets';
import HourlyReceipts from '../Common/HourlyReceipts';
import Perspective from '../Types/Perspective';
import { DismissibleModal } from '../Components/Modal';
import { htmlIf, maybeHtml, formatMultiParagraphString } from '../Utils/HTML';
import { formatUSDWithCents } from '../Utils/Currency';
import RequestDescription from '../Common/RequestDescription';
import { copilotProfileUrl, copilotMessagesUrl } from '../Types/Copilot';
import { FiveStarRating, RatingModalButton, ViewSubmittedRating } from './Components/Ratings';

type Props =
  { contract: HourlyContract
  , negotiationEnabled: boolean
  , perspective: Perspective
  }

enum Modal
  { NoModal
  , CancelRequestModal
  , ReassignRequestModal
  , SuccessfulReassignToPoolModal
  , AcceptOfferModal
  , DeclineOfferModal
  , CompleteRequestModal
  }

function messagesPath(perspective: Perspective, copilot?: Copilot) {
  switch(perspective) {
    case Perspective.Agent:
      return copilot ? `/agent/messages?other_user_id=${copilot.userId}` : '/agent/messages'
    case Perspective.Property:
      return copilot ? `/property/messages?other_user_id=${copilot.userId}` : '/property/messages'
  }
}

function requestsPath(perspective: Perspective) {
  switch(perspective) {
    case Perspective.Agent:
      return '/agent/requests'
    case Perspective.Property:
      return '/property/'
  }
}

function reassignRequestToCopilotPath(perspective: Perspective, contract: HourlyContract) {
  switch(perspective) {
    case Perspective.Agent:
      return `/agent/requests/reassign/h/${contract.id}`
    case Perspective.Property:
      return `/property/requests/reassign/h/${contract.id}`
  }
}

function editRequestPath(perspective: Perspective, contract: HourlyContract) {
  switch(perspective) {
    case Perspective.Agent:
      return `/agent/requests/edit/h/${contract.id}`
    case Perspective.Property:
      return `/property/requests/edit/h/${contract.id}`
  }
}

const RequestDetails = (props: Props) => {
  const [contract, setContract] = useState(props.contract);
  const [modal, setModal] = useState(Modal.NoModal);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [rating, setRating] = useState(props.contract.customerRating?.rating);
  const [ratingComment, setRatingComment] = useState(props.contract.customerRating?.ratingComment);
  const [ratingCompletedOnPageLoad, setRatingCompletedOnPageLoad] = useState(props.contract.customerRating !== null);

  const [selectedOffer, setSelectedOffer] = useState<HourlyContractOffer>();

  function cancelRequest(contract: HourlyContract) {
    setIsSubmitting(true);
    const body = { requestType: RequestType.HOURLY_CONTRACT, requestId: contract.id }
    API.post("api_requests_withdraw_request_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setContract(result['contract'])
      }
      setModal(Modal.NoModal);
      setIsSubmitting(false);
    })
  }

  function completeRequest(contract: HourlyContract) {
    setIsSubmitting(true);
    const body = { requestType: RequestType.HOURLY_CONTRACT, requestId: contract.id }
    API.post("api_requests_complete_request_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setContract(result['contract'])
      }
      setModal(Modal.NoModal);
      setIsSubmitting(false);
    })
  }

  function acceptContractOffer(offer: HourlyContractOffer) {
    setIsSubmitting(true);
    const body = { requestType: RequestType.HOURLY_CONTRACT, offerId: offer.id }
    API.post("api_requests_accept_offer_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setContract(result['contract'])
        setModal(Modal.NoModal)
        setIsSubmitting(false);
      }
    })
  }

  function declineContractOffer(offer: HourlyContractOffer) {
    setIsSubmitting(true);
    const body = { requestType: RequestType.HOURLY_CONTRACT, offerId: offer.id }
    API.post("api_requests_decline_offer_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setContract(result['contract'])
        setModal(Modal.NoModal)
        setIsSubmitting(false);
      }
    })
  }

  function reassignRequestToPool(contract: HourlyContract) {
    const body = { requestId: contract.id, requestType: RequestType.HOURLY_CONTRACT }
    API.post("api_requests_reassign_request_path", body).then(function (result) {
      if (result['error']) {
        setModal(Modal.NoModal);
      } else {
        setContract(result['contract'])
        setModal(Modal.SuccessfulReassignToPoolModal);
      }
    })
  }

  function reassignRequestToCopilot(contract: HourlyContract) {
    window.location.href = reassignRequestToCopilotPath(props.perspective, contract);
    setModal(Modal.NoModal);
  }

  function formatDate(dateString: string):string {
    const date = new Date(dateString)
    return (
      date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    )
  }

  const CancelRequestButton = () => (
    <button className="btn btn-outline-danger px-3 t--cancel-request" onClick={() => setModal(Modal.CancelRequestModal)}>
      Cancel Request
    </button>
  )

  const ReassignRequestButton = () => (
    <button className="btn btn-primary px-3 me-1 t--reassign-request" onClick={() => setModal(Modal.ReassignRequestModal)}>
      Reassign Request
    </button>
  )

  const CompleteRequestButton = () => (
    <button className="btn btn-link text-danger px-3 me-1 t--complete-request" onClick={() => setModal(Modal.CompleteRequestModal)}>
      Close Request
    </button>
  )

  const ViewMessagesButton = () => (
    <a className="btn btn-outline-primary px-3 t--view-messages" href={copilotMessagesUrl(contract.copilot)}>Messages</a>
  )

  const EditRequestButton = () => (
    <a className="btn btn-outline-primary px-3 me-1 t--edit-request" href={editRequestPath(props.perspective, contract)}>
      Edit Request
    </a>
  )

  const ViewModal = () => {
    switch(modal) {
      case Modal.NoModal:
        return null
      case Modal.CancelRequestModal:
        return (
          <DismissibleModal
            title={<h4>Cancel this request?</h4>}
            body={
              <div>
                Are you sure you want to cancel this hourly request?
                <div className="mt-2">
                  It will no longer be available to any CoPilots and you will not be charged for anything related to this request.
                </div>
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-primary w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  No, keep it
                </button>
                <button className="btn btn-danger w-100 w-sm-auto ms-sm-2 t--confirm-cancel-request"
                  onClick={() => cancelRequest(contract)}
                  disabled={isSubmitting}
                >
                  Yes, cancel it
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.ReassignRequestModal:
        return (
          <DismissibleModal
            title={<h4>Reassign this request</h4>}
            body={
              <div>
                The CoPilot you originally selected is unavailable or has declined the request. What would you like to do?
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-primary w-100 mb-2 t--request-another-copilot" onClick={() => reassignRequestToCopilot(contract)}>
                  Request another CoPilot
                </button>
                <button className="btn btn-primary w-100 t--confirm-reassign-to-pool" onClick={() => reassignRequestToPool(contract)}>
                  Open request to all CoPilots
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.SuccessfulReassignToPoolModal:
        return (
          <DismissibleModal
            title={<h4>Request successfully reassigned!</h4>}
            body={<div>Your request has been opened to all CoPilots. You will receive an email when it is claimed!</div>}
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.AcceptOfferModal:
        return (
          <DismissibleModal
            title={<h4>Accept offer</h4>}
            body={<div>Are you sure that you want to accept the offer from {selectedOffer.copilot.preferredName}?</div>}
            footer={
              <>
                <button className="btn btn-primary w-100 w-sm-auto mb-2 mb-sm-0 t--confirm-accept-offer"
                  onClick={() => acceptContractOffer(selectedOffer)}
                  disabled={isSubmitting}
                >
                  Yes, accept offer
                </button>
                <button className="btn btn-outline-primary w-100 w-sm-auto ms-sm-2"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.DeclineOfferModal:
        return (
          <DismissibleModal
            title={<h4>Decline offer</h4>}
            body={<div>Are you sure that you want to decline the offer from {selectedOffer.copilot.preferredName}?</div>}
            footer={
              <>
                <button className="btn btn-danger w-100 w-sm-auto mb-2 mb-sm-0 t--confirm-decline-offer"
                  onClick={() => declineContractOffer(selectedOffer)}
                  disabled={isSubmitting}
                >
                  Yes, decline offer
                </button>
                <button className="btn btn-outline-primary w-100 w-sm-auto ms-sm-2"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.CompleteRequestModal:
        return (
          <DismissibleModal
            title={<h4>Close this request?</h4>}
            body={
              <>
                <div className="fw-semibold mb-1 text-gray-900">Are you sure you want to close this request?</div>
                <div>
                  Your CoPilot will no longer be able to log any additional
                  work and you will be billed for any remaining unpaid hours.
                </div>
              </>
            }
            footer={
              <>
                <button className="btn btn-primary w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  No, keep it
                </button>
                <button className="btn btn-outline-danger w-100 w-sm-auto ms-sm-2 t--confirm-complete-request"
                  onClick={() => completeRequest(contract)}
                  disabled={isSubmitting}
                >
                  Yes, close it
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      default:
        return null
    }
  }

  const ViewRequestHeader = () => (
    <div className="p-3 border-bottom">
      <div className="">
        <div className="row align-items-center">
          <div className="col-lg d-lg-flex align-items-center">
            <h3 className="mb-0 me-1">{contract.title}</h3>
            <CustomerRequestStatusBadge status={contract.status}/>
          </div>
          <div className="col-auto d-flex justify-content-end mt-1 mt-lg-0">
            {htmlIf(contract.status === ContractStatus.DECLINED_PENDING,
              <ReassignRequestButton />
            )}
            {htmlIf(contract.status === ContractStatus.SUBMITTED,
              <EditRequestButton />
            )}
            {htmlIf(contract.status === ContractStatus.SUBMITTED || contract.status === ContractStatus.DECLINED_PENDING,
              <CancelRequestButton />
            )}
            {htmlIf(contract.status === ContractStatus.CLAIMED,
              <CompleteRequestButton />
            )}
            {htmlIf(contract.status === ContractStatus.CLAIMED || contract.status === ContractStatus.COMPLETED,
              <ViewMessagesButton />
            )}
          </div>
        </div>
        <div className="mt-1">
          <div className="fs-sm">
            Submitted
            <span className=""> {formatDate(contract.createdAt)}</span>
            {htmlIf(contract.status === ContractStatus.WITHDRAWN && !!contract.withdrawnAt,
              <>
                <span className="mx-1">•</span>
                Cancelled
                <span className=""> {formatDate(contract.withdrawnAt)}</span>
              </>
            )}
            {htmlIf(contract.status === ContractStatus.COMPLETED && !!contract.completedAt,
              <>
                <span className="mx-1">•</span>
                Completed
                <span className=""> {formatDate(contract.completedAt)}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  const ViewRequestOverview = () => {
    return (
      <div className="p-3">
        <div className="pb-2 border-bottom">
          {RequestDescription(contract.description, contract.tags)}
        </div>
        <div className="row">
          <div className="col-6 mt-2 d-flex align-items-center">
            <i className="ai-tag text-gray-900 fs-xxl me-1" />
            { contract.acceptingOffers
            ? "Accepting offers"
            : `${formatUSDWithCents(contract.hourlyRateCents)}/hour`
            }
          </div>
          <div className="col-6 mt-2 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="avatar avatar-24">
                <img className="avatar-img rounded-circle" src={contract.customerImageUrl}/>
              </div>
              <div className="ms-1">{contract.customerName}</div>
            </div>
          </div>
          <div className="col-6 mt-2 d-flex align-items-center">
            { contract.copilot === null
            ? <div className="">
                <i className="ai-user text-gray-900 fs-xxl me-1" />
                Unassigned
              </div>
            : <div className="d-flex align-items-center">
                <div className="avatar avatar-24">
                  <img className="avatar-img rounded-circle" src={contract.copilot.imageUrl}/>
                </div>
                <div className="ms-1">{contract.copilot.preferredName}</div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  const ViewRating = () => (
    <div className="p-3 bg-accent rounded-bottom border-top">

      {htmlIf(!ratingCompletedOnPageLoad, maybeHtml(contract.customerRating, (rating) => (
        <div className="alert alert-info p-3">
          Rating submitted. Thank you for your feedback!
        </div>
      )))}
      <h5>{"How would you rate your experience with " + contract.copilot?.preferredName + " on this request?"}</h5>
      { contract.customerRating
      ? <>
          {ViewSubmittedRating(contract.customerRating)}
        </>
      : <>
          <div className="row mb-3">
            <FiveStarRating rating={rating} setRating={setRating} starClass={'h2 mb-0 text-primary pe-1'}/>
          </div>
          <RatingModalButton
            requestType={RequestType.HOURLY_CONTRACT}
            requestId={contract.id}
            copilotName={contract.copilot?.preferredName}
            presetOverallRating={rating}
            buttonClass="btn btn-primary px-3 t--submit-rating"
            buttonText='Continue'
            requestSetter={setContract}
          />
        </>
      }

    </div>
  )

  const ViewOffers = () => {
    const pendingOffers = contract.offers.filter((offer) => offer.status === ContractOfferStatus.PENDING)

    function handleAcceptOfferClicked(offer: HourlyContractOffer) {
      setSelectedOffer(offer);
      setModal(Modal.AcceptOfferModal);
    }

    function handleDeclineOfferClicked(offer: HourlyContractOffer) {
      setSelectedOffer(offer);
      setModal(Modal.DeclineOfferModal);
    }

    function ViewSingleOffer(offer: HourlyContractOffer, lastOffer: boolean) {
      return (
        <div className={`pb-2 rounded-2 row g-0 no-gutters ${lastOffer ? "" : "border-bottom mb-2"}`}>
          <div className="col-auto me-1">
            <div className="col d-flex align-items-center text-dark">
              <div className="avatar avatar-32">
                <img className="avatar-img rounded-circle" src={offer.copilot.imageUrl}/>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row align-items-center">
              <div className="col d-flex align-items-center">
                <div className="h4 text-gray-700 mb-0">{offer.copilot.preferredName}</div>
              </div>
              <div className="col-auto fs-md">
                {formatUSDWithCents(offer.hourlyRateCents)} / hour
              </div>
            </div>
            <div className="mt-1 fs-md">{formatMultiParagraphString(offer.description)}</div>
            <div className="mt-1 fs-sm text-gray-700">
              { offer.lastEditedAt
              ? `${formatDate(offer.lastEditedAt)} (edited)`
              : formatDate(offer.createdAt)
              }
            </div>
            <div className="">
              <button className="btn btn-outline-success mt-1 t--accept-offer" onClick={() => handleAcceptOfferClicked(offer)}>Accept offer</button>
              <button className="btn btn-outline-danger mt-1 ms-2 t--decline-offer" onClick={(() => handleDeclineOfferClicked(offer))}>Decline offer</button>
              <a href={copilotProfileUrl(offer.copilot)} target="_blank" className="btn btn-outline-primary mt-1 ms-2 px-2">
                <i className="ai-external-link me-1" />
                View Profile
              </a>
              <a href={messagesPath(props.perspective, offer.copilot)} className="btn btn-link mt-1 ms-2 px-0">
                Message
              </a>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="p-3 border-top">
        <h3>Offers</h3>
        {htmlIf(pendingOffers.length === 0,
          <div>
            It looks like you haven't received any offers from CoPilots just yet — you'll get an email
            as soon as you receive an offer!
          </div>
        )}
        {pendingOffers.map((offer, index) =>
          <div key={offer.id}>
            { offer.status === ContractOfferStatus.PENDING
            ? ViewSingleOffer(offer, index + 1 === pendingOffers.length)
            : null
            }
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <ViewModal />
      <div className="row align-items-end">
        <div className="col-auto d-flex align-items-center">
          <a href={requestsPath(props.perspective)} className="text-decoration-none align-items-center d-flex">
            <i className="ai-arrow-left me-1"/>
            <i className="ai-grid me-1"/>
            All requests
          </a>
        </div>
      </div>
      <div className="card rounded-2 mt-1">
        {ViewRequestHeader()}
        {ViewRequestOverview()}
        {htmlIf(contract.status === ContractStatus.SUBMITTED || contract.status === ContractStatus.DECLINED_PENDING,
          <>
            { props.negotiationEnabled && contract.hourlyRateCents === 0
            ? <ViewOffers />
            : null
            }
          </>
        )}
        {htmlIf(contract.status === ContractStatus.CLAIMED || contract.status === ContractStatus.COMPLETED || contract.status === ContractStatus.WITHDRAWN,
          <>
            <div className="p-3 border-top">
              <HourlyTimesheet
                contract={contract}
                perspective={props.perspective}
                setParentContract={setContract}
              />
            </div>
            <div className="p-3 border-top">
              {HourlyReceipts(contract, props.perspective)}
            </div>
          </>
        )}
        {htmlIf(contract.copilot !== null &&
          (contract.status === ContractStatus.COMPLETED ||
            (contract.status === ContractStatus.CLAIMED && contract.payments.length >= 2)),
          ViewRating()
        )}
      </div>
    </div>
  )
};


export default RequestDetails;

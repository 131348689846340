import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Copilot } from '../Types/Copilot';
import { CurrencyCode, ExchangeRate } from '../Utils/Currency';
import { CopilotCard } from './Components/CopilotCard';
import { Tag } from '../Types/Tag';
import { htmlIf } from '../Utils/HTML';

type Props =
  { copilots: Copilot[]
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  , featuredTags: Tag[]
  }

const BestMatchCopilots = (props: Props) => {
  const [showAll, setShowAll] = useState(false);

  const firstSixCopilots = props.copilots.slice(0, 6);
  const remainingCopilots = props.copilots.slice(6);

  return (
    <>
      <div className="row mt-3">
        {firstSixCopilots.map((copilot) =>
          <div key={copilot.id} className="mt-2 col-12 col-md-6 col-xl-4 mb-4">
            <CopilotCard
              copilot={copilot}
              cardButtons={[]}
              featuredTags={props.featuredTags}
              displayCurrency={props.displayCurrency}
              exchangeRates={props.exchangeRates}
              clickToViewProfile={true}
              badgeText={'🌟 Best match'}
              key={copilot.id}
            />
          </div>
        )}
      </div>
      {htmlIf(!showAll,
        <button className="btn btn-link px-0 mt-n2" onClick={() => setShowAll(true)}>
          View all
        </button>
      )}
      {htmlIf(showAll,
        <div className="row">
          {remainingCopilots.map((copilot) =>
            <div key={copilot.id} className="mt-2 col-12 col-md-6 col-xl-4 mb-4">
              <CopilotCard
                copilot={copilot}
                cardButtons={[]}
                featuredTags={props.featuredTags}
                displayCurrency={props.displayCurrency}
                exchangeRates={props.exchangeRates}
                clickToViewProfile={true}
                key={copilot.id}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default BestMatchCopilots;

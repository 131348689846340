import * as React from 'react';
import { useState } from 'react';
import * as API from '../Utils/API.js';
import { HourlyContract, HourlyWorkItem, WorkItemStatus } from '../Types/Hourly';
import Perspective from '../Types/Perspective';
import { DismissibleModal } from '../Components/Modal';
import { htmlIf, maybeHtml } from '../Utils/HTML';

function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
}

enum Modal
  { NoModal
  , DeleteWorkItem
  , EditWorkItem
  }

type Props =
  { contract: HourlyContract
  , perspective: Perspective
  , setParentContract: (contract: HourlyContract) => void
  /** Should only be set on the CoPilot view. When called, should trigger the "Log Work" modal to appear. */
  triggerLogWorkModal?: () => void;
  }

export const HourlyTimesheet = ({contract, perspective, setParentContract, triggerLogWorkModal}: Props) => {
  const [modal, setModal] = useState(Modal.NoModal);
  const [showModalErrors, setShowModalErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [workItemToDelete, setWorkItemToDelete] = useState<HourlyWorkItem>();
  const [workItemToEdit, setWorkItemToEdit] = useState<HourlyWorkItem>();
  const [editDescription, setEditDescription] = useState<string>();

  const [numWorkItemsToDisplay, setNumWorkItemsToDisplay] = useState(10);

  const formatHoursMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${String(hours).padStart(1, "0")}:${String(remainingMinutes).padStart(2, "0")}`;
  };

  const sumMinutesLast24Hours = contract.workItems
    .filter(({ createdAt }) => new Date(createdAt) > new Date(Date.now() - 24 * 60 * 60 * 1000))
    .reduce((sum, { durationMinutes }) => sum + durationMinutes, 0);

  const sumMinutesLast7Days = contract.workItems
    .filter(({ createdAt }) => new Date(createdAt) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
    .reduce((sum, { durationMinutes }) => sum + durationMinutes, 0);

  const sumMinutesSinceStart = contract.workItems.reduce((sum, { durationMinutes }) => sum + durationMinutes, 0);

  const ViewStatusBadge = (workItem: HourlyWorkItem) => {
    switch (workItem.status) {
      case WorkItemStatus.UNPAID:
        return <div className="badge border text-dark fs-xs">Unpaid</div>
      case WorkItemStatus.PAYMENT_PENDING:
        return <div className="badge bg-faded-info text-dark fs-xs">Pending</div>
      case WorkItemStatus.COPILOT_PAID:
        return <div className="badge bg-faded-success text-success fw-bold fs-xs">Paid</div>
      case WorkItemStatus.AGENT_PAID:
        switch (perspective) {
          case Perspective.Agent:
          case Perspective.Property:
            return <div className="badge bg-faded-success text-success fw-bold fs-xs">Paid</div>
          case Perspective.Copilot:
            return <div className="badge bg-faded-info text-dark fs-xs">Pending</div>
        }
      case WorkItemStatus.REFUNDED:
        return <div className="badge bg-faded-danger text-danger fw-bold fs-xs">Refunded</div>
    }
  }

  function editWorkItem(workItem: HourlyWorkItem) {
    if (editDescription.length > 0) {
      setIsSubmitting(true);
      const body = { hourlyWorkItemId: workItem.id, description: editDescription }
      API.post("copilot_requests_edit_hourly_work_item_path", body).then(function (result) {
        if (result['error']) {
        } else {
          setParentContract(result['contract'])
        }
        setModal(Modal.NoModal);
        setWorkItemToDelete(null);
        setIsSubmitting(false);
      })
    } else {
      setShowModalErrors(true);
    }
  }

  function deleteWorkItem(workItem: HourlyWorkItem) {
    setIsSubmitting(true);
    const body = { hourlyWorkItemId: workItem.id }
    API.post("copilot_requests_delete_hourly_work_item_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setParentContract(result['contract'])
      }
      setModal(Modal.NoModal);
      setWorkItemToDelete(null);
      setIsSubmitting(false);
    })
  }

  function trashButtonClickHandler(workItem: HourlyWorkItem) {
    setWorkItemToDelete(workItem);
    setModal(Modal.DeleteWorkItem);
  }

  function editButtonClickHandler(workItem: HourlyWorkItem) {
    setWorkItemToEdit(workItem);
    setEditDescription(workItem.description);
    setModal(Modal.EditWorkItem);
  }

  function showMoreWorkItemsClickHandler() {
    setNumWorkItemsToDisplay(numWorkItemsToDisplay + 10);
  }

  const ViewModal = () => {
    switch(modal) {
      case Modal.NoModal:
        return null
      case Modal.DeleteWorkItem:
        return (
          <DismissibleModal
            title={<h4>Delete entry?</h4>}
            body={
              <div>
                <div className="">Would you like to delete this entry?</div>
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-primary w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  No, keep it
                </button>
                <button className="btn btn-danger w-100 w-sm-auto ms-sm-2"
                  onClick={() => deleteWorkItem(workItemToDelete)}
                  disabled={isSubmitting}
                >
                  Yes, delete it
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.EditWorkItem:
        return (
          <DismissibleModal
            title={<h4>Edit description</h4>}
            body={
              <div>
                <div className="mb-1">Enter an updated description:</div>
                <textarea className="form-control"
                    placeholder="Enter a brief description of the work you’re logging…"
                    rows={5}
                    value={editDescription}
                    onChange={(event) => setEditDescription(event.target.value)}
                  />
                  {htmlIf(showModalErrors && !editDescription.length,
                    <div className="fs-sm mt-1 text-danger fw-bold">Please enter a description.</div>
                  )}
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button className="btn btn-primary w-100 w-sm-auto ms-sm-2"
                  onClick={() => editWorkItem(workItemToEdit)}
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
    }
  }

  return (
    <>
      {ViewModal()}
      <div className="">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="mb-0">Timesheet</h3>
          {maybeHtml(triggerLogWorkModal, (trigger) => (
            <button className="btn btn-primary px-3 ms-2" onClick={trigger}>
              Log Work
            </button>
          ))}
        </div>
        <div className="row mt-2">
          <div className="col-md-4">
            <div className="fw-bold">{formatHoursMinutes(sumMinutesLast24Hours)} hrs</div>
            <div className="">Last 24 hours</div>
          </div>
          <div className="col-md-4">
            <div className="fw-bold">{formatHoursMinutes(sumMinutesLast7Days)} hrs</div>
            <div className="">Last 7 days</div>
          </div>
          <div className="col-md-4">
            <div className="fw-bold">{formatHoursMinutes(sumMinutesSinceStart)} hrs</div>
            <div className="">Since start</div>
          </div>
        </div>
        <div className="mt-2 table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Date</th>
                <th>Duration</th>
                <th>Status</th>
                {htmlIf(perspective === Perspective.Copilot,
                  <th></th> // Left blank — this is where we have the trash button
                )}
              </tr>
            </thead>
            <tbody>
              {contract.workItems
                .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                .slice(0, numWorkItemsToDisplay)
                .map((item, index) => (
                  <tr className={`t--hourly-work-item-id-${item.id}`} key={index}>
                    <td>{item.description}</td>
                    <td className="text-nowrap">{formatDate(item.createdAt)}</td>
                    <td className="text-center">{formatHoursMinutes(item.durationMinutes)}</td>
                    <td className="text-center">{ViewStatusBadge(item)}</td>
                    {htmlIf(perspective === Perspective.Copilot,
                      <td className="text-center">
                      {htmlIf(item.status === WorkItemStatus.UNPAID,
                        <div className="">
                          <i className="ai-edit-alt text-secondary cursor-pointer fs-lg" onClick={() => editButtonClickHandler(item)} />
                          <i className="ms-1 ai-trash text-danger cursor-pointer fs-lg" onClick={() => trashButtonClickHandler(item)} />
                        </div>
                      )}
                      </td>
                    )}
                  </tr>
              ))}
            </tbody>
          </table>
          {htmlIf(numWorkItemsToDisplay < contract.workItems.length,
            <button className="w-100 btn btn-link py-0" onClick={showMoreWorkItemsClickHandler}>Show more</button>
          )}
        </div>
      </div>
    </>
  )
}

export default HourlyTimesheet;
